<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Chuyển Xu</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Chuyển Xu</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Username transfers</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập username transfers"
                      v-model="username_transfers"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Username receiver</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập username receiver"
                      v-model="username_receiver"
                    />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Chuyển Xu'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center">
              <button class="btn btn-success font-10 py-1 btn-xs">Action</button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import logsTransferApi from "@/api/logs-transfer";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";

export default {
  name: "LogsTransfer",
  components: {
    Datatable,
    LoadingVue,
  },
  data() {
    return {
      username_transfers: null,
      username_receiver: null,
      limit: 50,
      logs: [],
      loading: false,
      columns: [
        { label: "Username transfers", field: "username_transfers" },
        { label: "User id transfers", field: "user_id_transfers" },
        { label: "Username receiver", field: "username_receiver" },
        { label: "User id receiver", field: "user_id_receiver" },
        { label: "Coin", field: "coin", numeric: true },
        { label: "Status", field: "status_html", html: true },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedLogs() {
      return this.logs.map((log) => {
        log.status_html = this.statusMessage(log.status, log.status_message);
        return log;
      });
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
        username_transfers: this.username_transfers,
        username_receiver: this.username_receiver,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await logsTransferApi.list(queries);
      this.loading = false;
      if (response.success) {
        this.logs = response.data;
      } else {
        this.$toastr.error(response.message);
      }
    },
    statusMessage(id, message) {
      switch (id) {
        case 0:
        case "0":
          return `<span class='text-primary'>${message}</span>`;
        case 1:
        case "1":
          return `<span class='text-success'>${message}</span>`;
        default:
          return message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
